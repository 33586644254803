import { useFeature } from '@growthbook/growthbook-react';
import { AppfolioLanding } from 'components/AppfolioLanding';
import { QuizWrapper } from 'components/QuizWrapper';
import { MicrositeContext } from 'context';
import { useQual } from 'hooks/useQual';
import { useStore } from 'hooks/useStore';
import router from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

export function Landing() {
  const { enableAppfolioLanding = false, skipToPlansOnRevisit = false } =
    useContext(MicrositeContext);
  const { on: appfolioLandingFlagEnabled } = useFeature(
    'provider_app_appfolio_landing'
  );
  const visitingQual = useStore((store) => store.visitingQual);
  const setVisitingQual = useStore((store) => store.setVisitingQual);
  const [acceptedUpdaterTerms, setAcceptedUpdaterTerms] = useStore(
    useShallow((store) => [
      store.acceptedUpdaterTerms,
      store.setAcceptedUpdaterTerms,
    ])
  );
  const [visitChecked, setVisitChecked] = useState(false);
  const qual = useQual();
  useEffect(() => {
    const qualEncoded = btoa(
      unescape(encodeURIComponent(JSON.stringify(qual)))
    );
    if (!qualEncoded.length || qualEncoded !== visitingQual) {
      setAcceptedUpdaterTerms(false);
      setVisitChecked(true);
      setVisitingQual(qualEncoded);
    } else if (skipToPlansOnRevisit && acceptedUpdaterTerms) {
      router.push('plans');
    } else {
      setVisitChecked(true);
    }
  }, []);
  if (!visitChecked) return <></>;
  if (enableAppfolioLanding && appfolioLandingFlagEnabled)
    return <AppfolioLanding />;
  return <QuizWrapper />;
}
