import { ShopWrapper, ProviderWrapper } from 'components';
import { Landing } from 'components/Landing';

export default function QuizPage() {
  return (
    <ProviderWrapper>
      <ShopWrapper>
        <Landing />
      </ShopWrapper>
    </ProviderWrapper>
  );
}
