import styled from '@emotion/styled';
import { Smiley, MoneyWavy, Trophy, IconContext } from '@phosphor-icons/react';
import {
  Box,
  Text,
  Palette,
  Button,
  TextLink,
  Flex,
  Image,
  useViewportSizes,
  theme,
  ColorKey,
} from '@updater/ui-uds';
import { BorderRadiusKey } from '@updater/ui-uds/dist/es/types/theme-types';
import { useQual, useStore } from 'hooks';
import { useRouter } from 'next/router';
import { MouseEventHandler, useCallback } from 'react';
import { Events, usePageEvent, useTracking } from 'services';
import { ResponsiveValue, Theme } from 'styled-system';
import { useShallow } from 'zustand/react/shallow';
const ValueListItem = styled(Box)`
  height: 144px;
  padding: ${({ theme }) => `${theme.space.s}px`};
  background-color: ${Palette.blue10};
  border-radius: ${({ theme }) => theme.button.borderRadii.m};
  svg {
    margin: 0 auto;
    display: block;
  }
`;

const CtaSection: React.FC<{
  onClick: MouseEventHandler<HTMLElement>;
}> = ({ onClick }) => {
  return (
    <Flex
      width="100%"
      maxWidth="620px"
      marginTop="none"
      marginBottom="l"
      marginX="auto"
      flexDirection="column"
      gap={`${theme.space.s}px`}
    >
      <Button onClick={onClick} size="l" name="plans" variant="primary">
        See all plans
      </Button>
      <Text variant="xs" textAlign="center">
        By continuing, you agree to the{' '}
        <TextLink
          data-testid="termsAndPrivacyLink"
          showTextDecoration
          href="https://updater.com/uhs-terms/"
        >
          Updater terms of service and privacy policy
        </TextLink>
        .
      </Text>
    </Flex>
  );
};

export function AppfolioLanding() {
  const router = useRouter();
  const tracking = useTracking();
  const { isMobile } = useViewportSizes();
  const { userFirstName, userLastName, zip, city, state, apartment, street } =
    useQual();
  const [setAcceptedUpdaterTerms] = useStore(
    useShallow((store) => [store.setAcceptedUpdaterTerms])
  );
  usePageEvent(Events.APPFOLIO_LANDING);

  const routeToPlans = useCallback(() => {
    const details = {
      user_address: {
        street,
        unit: apartment,
        city,
        state,
        zip,
      },
      first_name: userFirstName,
      last_name: userLastName,
    };
    setAcceptedUpdaterTerms(true);
    tracking.trackEvent(Events.PRIVACY_AND_TERMS_CONSENT, 'accepted', details);
    tracking.trackEvent(Events.SKIP_QUESTIONS, 'clicked', details);
    router.push('/plans');
  }, [
    apartment,
    city,
    router,
    setAcceptedUpdaterTerms,
    state,
    street,
    tracking,
    userFirstName,
    userLastName,
    zip,
  ]);

  return (
    <Box width="100%" maxWidth="940px" marginY="l" marginX="auto">
      <Box
        width="100%"
        maxWidth="620px"
        marginY="none"
        marginX="auto"
        borderBottom="1px solid ${Palette.gray80}"
      >
        <Box
          width="100%"
          height={['200px', '200px', '200px', '240px', '240px']}
          backgroundColor="softBlue"
          // 12px is missing as an option in standard borderRadii
          borderRadius={
            theme.button.borderRadii.m as ResponsiveValue<
              BorderRadiusKey,
              Theme
            >
          }
        >
          <Image
            width={['231px', '231px', '231px', '277px', '277px']}
            height="100%"
            marginY="none"
            marginX="auto"
            display="block"
            src="/provider/assets/tvi.svg"
            alt="A minimalist illustration showing a TV, a laptop, and a router."
          />
        </Box>
        <Text as="h1" variant="xxlBold" marginY="m" textAlign="center">
          Hi, we're Updater!
        </Text>
        <Text as="p" variant="l" marginY="m" textAlign="center">
          Updater helps you compare and choose the best internet and TV plans.
          Get connected on day one with seamless setup.
        </Text>
        <CtaSection onClick={routeToPlans} />
      </Box>
      <Box
        maxWidth={['100%', '620px', '620px', '940px', '940px']}
        marginX="auto"
      >
        <Text as="h2" variant="xl" marginY="m" textAlign="center">
          Why shop with Updater?
        </Text>
        <Flex
          width="100%"
          gap={`${theme.space.s}px`}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <IconContext.Provider
            value={{
              color: Palette.blue60,
              size: '32px',
              weight: 'light',
            }}
          >
            <ValueListItem width={['100%', '100%', '100%', '302px', '302px']}>
              <Smiley />
              <Text as="h3" variant="mBold" marginY="xxs" textAlign="center">
                Save time & sanity
              </Text>
              <Text as="p" variant="s" marginY="xxs" textAlign="center">
                Compare internet plans like flights - all at once.
              </Text>
            </ValueListItem>
            <ValueListItem width={['100%', '100%', '100%', '302px', '302px']}>
              <MoneyWavy />
              <Text as="h3" variant="mBold" marginY="xxs" textAlign="center">
                We search - you save
              </Text>
              <Text as="p" variant="s" marginY="xxs" textAlign="center">
                No added fees or markups, ever.
              </Text>
            </ValueListItem>
            <ValueListItem width={['100%', '100%', '100%', '302px', '302px']}>
              <Trophy />
              <Text as="h3" variant="mBold" marginY="xxs" textAlign="center">
                Over 5 million customers helped
              </Text>
              <Text as="p" variant="s" marginY="xxs" textAlign="center">
                4.8-stars across 4,000+ reviews in app stores and on Trustpilot.
              </Text>
            </ValueListItem>
          </IconContext.Provider>
        </Flex>
        <Flex
          width="100%"
          max-width={['620px', '620px', '620px', '100%', '100%']}
          marginY="l"
          marginX="auto"
          padding="xs"
          // 12px is missing as an option in standard borderRadii
          borderRadius={
            theme.button.borderRadii.m as ResponsiveValue<
              BorderRadiusKey,
              Theme
            >
          }
          backgroundColor={
            Palette.beige10 as unknown as ResponsiveValue<ColorKey, Theme>
          }
          flexDirection={['column', 'column', 'column', 'row', 'row']}
          alignItems={[
            'center',
            'center',
            'center',
            'flex-start',
            'flex-start',
          ]}
          textAlign={['center', 'center', 'center', 'left', 'left']}
        >
          <Box margin="s">
            <Image
              height="36px"
              width="177px"
              src="/provider/assets/provider-icons.svg"
              alt="Logos of popular internet service providers."
            />
          </Box>
          <Box margin="xs">
            <Text as="h3" variant="mBold" marginY="xxs">
              Compare the providers you need
            </Text>
            <Text as="p" variant="s" marginY="xxs">
              Wherever you're moving, we've got you covered.
            </Text>
          </Box>
        </Flex>
      </Box>
      {isMobile && <CtaSection onClick={routeToPlans} />}
    </Box>
  );
}
